//applyify-frontend\src\pages\SignupPage.jsx file:
import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import { useNavigate, useLocation } from "react-router-dom";
import "./SignupPage.scss";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [notification, setNotification] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      localStorage.setItem("token", token);
      navigate("/profile");
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/auth/register", {
        email,
        password,
        firstName,
        lastName,
      });
      setNotification(
        "Registration successful! Please check your email to confirm."
      );
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const handleOAuthSignup = (provider) => {
    const backendUrl =
      process.env.REACT_APP_BACKEND_URL || "http://localhost:5000/api";
    window.location.href = `${backendUrl}/auth/${provider}`;
  };

  return (
    <div className="signup">
      <h1 className="signup__title">Sign Up</h1>
      {notification && <p className="signup__notification">{notification}</p>}
      <form className="signup__form" onSubmit={handleSubmit}>
        <div className="signup__form-fields">
          <label className="signup__label">First Name</label>
          <input
            className="signup__input"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="signup__form-fields">
          <label className="signup__label">Last Name</label>
          <input
            className="signup__input"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="signup__form-fields">
          <label className="signup__label">Email</label>
          <input
            className="signup__input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="signup__form-fields">
          <label className="signup__label">Password</label>
          <input
            className="signup__input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className="signup__button" type="submit">
          Sign Up
        </button>
      </form>
      <div className="oauth-buttons">
        <button
          className="button-google"
          onClick={() => handleOAuthSignup("google")}
        >
          <span className="button-google-icon"></span>
          <span className="button-google-text">Google</span>
        </button>
      </div>
    </div>
  );
};

export default SignupPage;
