//applyify-frontend\src\utils\auth.js file:
import { jwtDecode } from "jwt-decode";

export const getUserIdFromToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.userId;
  }
  return null;
};

export const getToken = () => localStorage.getItem("token");

export const removeToken = () => {
  localStorage.removeItem("token");
};
