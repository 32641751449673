//applyify-frontend\src\components\Job\JobForm.jsx file:
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosConfig";
import { getToken } from "../../utils/auth";
import "./JobForm.scss";

const JobForm = ({ setJobData }) => {
  const [formData, setFormData] = useState({
    jobDetails: "",
    companyAbout: "",
    jobTitle: "",
    companyName: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/jobposting", formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      const jobData = response.data.job;
      setJobData(formData);
      navigate("/dashboard");
    } catch (err) {
      console.error("Error submitting job posting:", err);
    }
  };

  return (
    <form className="add-job" onSubmit={handleSubmit}>
      <div className="add-job-form">
        <label className="add-job-form__label">Job Title:</label>
        <input
          className="add-job-form__input"
          type="text"
          name="jobTitle"
          value={formData.jobTitle}
          onChange={handleChange}
          placeholder="ex: Software Engineer"
          required
        />
      </div>
      <div className="add-job-form">
        <label className="add-job-form__label">Company Name:</label>
        <input
          className="add-job-form__input"
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          placeholder="ex: Amazon"
          required
        />
      </div>
      <div className="add-job-form">
        <label className="add-job-form__label">Job Requirements:</label>
        <textarea
          className="add-job-form__textarea"
          name="jobDetails"
          value={formData.jobDetails}
          onChange={handleChange}
          placeholder="Copy and paste the job requirements and responsibilities here..."
          required
        />
      </div>
      <div className="add-job-form">
        <label className="add-job-form__label">About the Company:</label>
        <textarea
          className="add-job-form__textarea"
          name="companyAbout"
          value={formData.companyAbout}
          onChange={handleChange}
          placeholder="Copy and paste the company's 'about' section from the job posting here..."
          required
        />
      </div>
      <button className="add-job-form__button" type="submit">
        Submit
      </button>
    </form>
  );
};

export default JobForm;
