//applyify-frontend\src\components\Footer\Footer.jsx file:
import React from "react";
import { NavLink } from "react-router-dom";
import FooterLogo from "../../assets/images/applyify-send-cover-letters-graphic.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__logo">
        <img src={FooterLogo} alt="Applyify logo" />
      </div>
      <div className="footer__links">
        <NavLink to="/contact-us" className="footer__link">
          Contact Us
        </NavLink>
        <NavLink to="/privacy-policy" className="footer__link">
          Privacy Policy
        </NavLink>
        <NavLink to="/terms-of-service" className="footer__link">
          Terms of Service
        </NavLink>
      </div>
      <div className="footer__copyright">
        <p className="footer__copyright-text">Copyright 2024 - Applyify.io</p>
      </div>
    </footer>
  );
};

export default Footer;
