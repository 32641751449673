//applyify-frontend\src\components\Sidebar\Sidebar.jsx file:
import React from "react";
import "./Sidebar.scss";

const Sidebar = ({ jobs, onJobClick }) => {
  return (
    <div className="sidebar">
      <h4>My Job Postings</h4>
      <ul className="sidebar__list">
        {jobs.map((job) => (
          <li key={job.id} className="sidebar__item">
            <a
              href="#"
              className="sidebar__link"
              onClick={(e) => {
                e.preventDefault();
                onJobClick(job.id);
              }}
            >
              {job.jobTitle} - {job.companyName}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
