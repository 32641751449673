//applyify-frontend\src\pages\PrivacyPolicy.jsx file:
import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1 className="privacy-policy__title">Privacy Policy</h1>
      <p>Last updated: July 24th, 2024</p>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">1. Introduction</h2>
        <p>
          Welcome to Applyify. We are committed to protecting your personal
          information and your right to privacy. If you have any questions or
          concerns about our policy, or our practices with regards to your
          personal information, please contact us at support@applyify.io.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">2. Information We Collect</h2>
        <p>
          We collect personal information that you provide to us such as name,
          address, contact information, passwords and security data, and payment
          information. This includes:
        </p>
        <ul className="privacy-policy__list">
          <li>Email address</li>
          <li>First and last name</li>
          <li>Account login details</li>
          <li>
            Profile information including skills, experiences, education, and
            projects
          </li>
          <li>Job posting details and company information</li>
        </ul>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">
          3. How We Use Your Information
        </h2>
        <p>We use the information we collect or receive:</p>
        <ul className="privacy-policy__list">
          <li>To facilitate account creation and the login process</li>
          <li>To send administrative information to you</li>
          <li>To fulfill and manage your orders</li>
          <li>To post testimonials</li>
          <li>To deliver targeted advertising to you</li>
          <li>To request feedback</li>
          <li>To protect our services</li>
          <li>To enforce our terms, conditions, and policies</li>
          <li>To respond to legal requests and prevent harm</li>
          <li>For other business purposes</li>
        </ul>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">
          4. Will Your Information Be Shared With Anyone?
        </h2>
        <p>
          We only share and disclose your information in the following
          situations:
        </p>
        <ul className="privacy-policy__list">
          <li>Compliance with Laws</li>
          <li>Vital Interests and Legal Rights</li>
          <li>Business Transfers</li>
          <li>Affiliates</li>
          <li>Business Partners</li>
          <li>With your Consent</li>
        </ul>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">
          5. How Long Do We Keep Your Information?
        </h2>
        <p>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy policy unless otherwise required by
          law.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">
          6. How Do We Keep Your Information Safe?
        </h2>
        <p>
          We aim to protect your personal information through a system of
          organizational and technical security measures.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">
          7. What Are Your Privacy Rights?
        </h2>
        <p>
          You have rights that allow you greater access to and control over your
          personal information. You may review, change, or terminate your
          account at any time.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">
          8. Controls for Do-Not-Track Features
        </h2>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">
          9. Do We Make Updates to This Policy?
        </h2>
        <p>
          Yes, we will update this policy as necessary to stay compliant with
          relevant laws.
        </p>
      </section>

      <section className="privacy-policy__section">
        <h2 className="privacy-policy__subtitle">
          10. How Can You Contact Us About This Policy?
        </h2>
        <p>
          If you have questions or comments about this policy, you may email us
          at support@applyify.io.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
