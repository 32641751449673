// applyify-frontend/src/components/LoadingMessages/LoadingMessages.jsx file:
import React, { useState, useEffect } from "react";
import "./LoadingMessages.scss";

const LoadingMessages = ({ isLoading }) => {
  const [message, setMessage] = useState("Analyzing your job posting...");

  useEffect(() => {
    if (!isLoading) return;

    const messages = [
      "Analyzing your job posting...",
      "Writing your cover letter...",
      "Adding the finishing touches...",
    ];

    let messageIndex = 0;

    const interval = setInterval(() => {
      messageIndex += 1;
      if (messageIndex < messages.length) {
        setMessage(messages[messageIndex]);
      }
    }, 6000);

    return () => clearInterval(interval);
  }, [isLoading]);

  return (
    <div className="loading-messages">
      <div className="loading-circle"></div>
      <p>{message}</p>
    </div>
  );
};

export default LoadingMessages;
