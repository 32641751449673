//applyify-frontend\src\components\Header\AuthHeader.jsx file:
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/applyify-high-resolution-logo-transparent-bl.png";
import "./Header.scss";

const AuthHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar__menu">
        <div className="navbar__logo">
          <NavLink to="/">
            <img className="navbar__logo-img" src={Logo} alt="Applyify logo" />
          </NavLink>
        </div>
        <div className={`navbar__items ${isOpen ? "navbar__items--open" : ""}`}>
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive ? "navbar__link--active" : "navbar__link"
            }
          >
            Profile
          </NavLink>
          <NavLink
            to="/job"
            className={({ isActive }) =>
              isActive ? "navbar__link--active" : "navbar__link"
            }
          >
            Job
          </NavLink>
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "navbar__link--active" : "navbar__link"
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/logout"
            className={({ isActive }) =>
              isActive ? "navbar__link--active" : "navbar__link"
            }
          >
            Logout
          </NavLink>
        </div>
        <div className="navbar__toggle" onClick={toggleMenu}>
          <div className="navbar__toggle-bar"></div>
          <div className="navbar__toggle-bar"></div>
          <div className="navbar__toggle-bar"></div>
        </div>
      </div>
    </nav>
  );
};

export default AuthHeader;
