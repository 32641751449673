//applyify-frontend\src\pages\ConfirmEmailPage.jsx file:
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosConfig";
import "./ConfirmEmailPage.scss";

const ConfirmEmailPage = ({ onLogin }) => {
  const [message, setMessage] = useState("");
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      const params = new URLSearchParams(search);
      const token = params.get("token");
      console.log("Token received:", token);
      try {
        const response = await axiosInstance.get(
          `/auth/confirm-email?token=${token}`
        );
        console.log("Confirmation response:", response.data);
        if (response.data.token) {
          setMessage("Email confirmed successfully! Redirecting...");
          localStorage.setItem("token", response.data.token);
          onLogin(); // Trigger login
          setTimeout(() => navigate("/profile"), 1000);
        } else {
          setMessage("Email confirmation failed. Please try again.");
        }
      } catch (error) {
        console.error("Error confirming email:", error);
        setMessage("Email confirmation failed. Please try again.");
      }
    };
    confirmEmail();
  }, [search, navigate, onLogin]);

  return (
    <div className="confirm-email">
      <h1 className="confirm-email__title">Email Confirmation</h1>
      <p className="confirm-email__message">{message}</p>
    </div>
  );
};

export default ConfirmEmailPage;
