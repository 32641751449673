//applyify-frontend\src\App.js file:
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Job from "./pages/Job";
import DashboardPage from "./pages/DashboardPage";
import SignupPage from "./pages/SignupPage";
import LoginPage from "./pages/LoginPage";
import ConfirmEmailPage from "./pages/account/ConfirmEmailPage";
import ForgotPasswordPage from "./pages/account/ForgotPasswordPage";
import ResetPasswordPage from "./pages/account/ResetPasswordPage";
import Logout from "./components/Logout/Logout";
import AuthHeader from "./components/Header/AuthHeader";
import UnauthHeader from "./components/Header/UnauthHeader";
import PrivacyPolicy from "./pages/zFooter/PrivacyPolicy";
import TermsOfService from "./pages/zFooter/TermsOfService";
import ContactUs from "./pages/zFooter/ContactUs";
import Footer from "./components/Footer/Footer";
import "./styles/main.scss";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  return token ? <Component {...rest} /> : <Navigate to="/login" />;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("token");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        {isAuthenticated ? <AuthHeader /> : <UnauthHeader />}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/login"
              element={<LoginPage onLogin={handleLogin} />}
            />
            <Route path="/signup" element={<SignupPage />} />
            <Route
              path="/logout"
              element={<Logout onLogout={handleLogout} />}
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute
                  element={() => <Profile onLogout={handleLogout} />}
                />
              }
            />
            <Route path="/job" element={<ProtectedRoute element={Job} />} />
            <Route
              path="/confirm-email"
              element={<ConfirmEmailPage onLogin={handleLogin} />}
            />
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={DashboardPage} />}
            />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
