//applyify-frontend\src\pages\ContactUs.jsx file:
import React, { useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import "./ContactUs.scss";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [notification, setNotification] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/contact", formData);
      setNotification(
        "Thank you for contacting us. We will get back to you soon."
      );
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      setNotification("Error sending message. Please try again.");
    }
  };

  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      {notification && (
        <p className="contact-us__notification">{notification}</p>
      )}
      <form className="contact-us__form" onSubmit={handleSubmit}>
        <div className="contact-us__form-fields">
          <label className="contact-us__label">Name</label>
          <input
            className="contact-us__input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-us__form-fields">
          <label className="contact-us__label">Email</label>
          <input
            className="contact-us__input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="contact-us__form-fields">
          <label className="contact-us__label">Message</label>
          <textarea
            className="contact-us__textarea"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button className="contact-us__button" type="submit">
          Send Message
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
