//applyify-frontend\src\components\Dashboard\JobPostings.jsx file:
import React, { useState } from "react";
import "./JobPostings.scss";

const JobPostings = ({
  jobs,
  documents,
  handleGenerateDocument,
  handleDeleteJob,
  handleEditCoverLetter,
  handleSaveCoverLetter,
  loadingJobId,
  editingDocumentId,
  editedCoverLetter,
  setEditedCoverLetter,
  profiles,
  handleDownloadPDF,
}) => {
  const getDocumentsForJob = (jobId) =>
    documents.filter((doc) => doc.jobId === jobId);

  const [expandedJobId, setExpandedJobId] = useState(null);

  const toggleJobDetails = (jobId) => {
    setExpandedJobId(expandedJobId === jobId ? null : jobId);
  };

  const renderCoverLetterContent = (htmlContent) => {
    const doc = new DOMParser().parseFromString(htmlContent, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      <h2>Job Postings</h2>
      {jobs.length === 0 ? (
        <p className="job-postings__empty">No job postings found.</p>
      ) : (
        jobs.map((job, index) => {
          const jobDocuments = getDocumentsForJob(job.id);

          return (
            <div className="job-posting" key={job.id} id={`job-${job.id}`}>
              <p>
                {job.jobTitle} - {job.companyName}
              </p>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleJobDetails(job.id);
                }}
                className="toggle-details-link"
              >
                {expandedJobId === job.id
                  ? "Hide Job Details"
                  : "View Job Details"}
              </a>
              {expandedJobId === job.id && (
                <div className="job-posting__details">
                  <ul className="job-posting__list">
                    <li className="job-posting__item">
                      <span className="bold-text">Requirements:</span>{" "}
                      {job.jobDetails}
                    </li>
                    <li className="job-posting__item">
                      <span className="bold-text">About Company:</span>{" "}
                      {job.companyAbout}
                    </li>
                  </ul>
                </div>
              )}
              {jobDocuments.length > 0 ? (
                jobDocuments.map((doc) => (
                  <div className="job-posting__document" key={doc.id}>
                    <h3>Cover Letter:</h3>
                    {editingDocumentId === doc.id ? (
                      <div className="job-posting__edit">
                        <textarea
                          className="job-posting__textarea"
                          value={editedCoverLetter}
                          onChange={(e) => setEditedCoverLetter(e.target.value)}
                        />
                        <button
                          className="job-posting__button"
                          onClick={() => handleSaveCoverLetter(doc.id)}
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      // updated content
                      <div className="job-posting__text">
                        <div className="job-posting__cover-letter-content">
                          {renderCoverLetterContent(doc.coverLetter)}
                        </div>
                        <button
                          className="job-posting__button-edit"
                          onClick={() => handleEditCoverLetter(doc)}
                        >
                          Edit
                        </button>
                        <button
                          className="job-posting__button-download"
                          onClick={() => handleDownloadPDF(doc.id)}
                        >
                          Download this as a PDF
                        </button>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="job-posting__generate">
                  <button
                    className="job-posting__button"
                    onClick={() => handleGenerateDocument(profiles[0], job)}
                    disabled={loadingJobId !== null}
                  >
                    Write My Cover Letter
                  </button>
                </div>
              )}
              <button
                className="job-posting__button-delete"
                onClick={() => handleDeleteJob(job.id)}
              >
                Delete Job
              </button>
            </div>
          );
        })
      )}
    </>
  );
};

export default JobPostings;
