//applyify-frontend\src\components\ProfileEdit\EditProfile.jsx file:
import React, { useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { getToken } from "../../utils/auth";
import "./EditProfile.scss";

const ProfileFormEdit = ({ profile, setProfileData }) => {
  const [formData, setFormData] = useState(profile);
  const [isEditing, setIsEditing] = useState({
    skills: false,
    experiences: false,
    education: false,
    projects: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEdit = (field) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const handleSave = async (field) => {
    try {
      await axiosInstance.put(
        `/profile/${formData.id}`,
        { [field]: formData[field] },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsEditing((prevState) => ({
        ...prevState,
        [field]: false,
      }));
      setProfileData(formData);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <form className="edit-profile">
      <div className="edit-profile-form">
        <label className="edit-profile-form__label">Skills:</label>
        {isEditing.skills ? (
          <>
            <textarea
              className="edit-profile-form__textarea"
              name="skills"
              value={formData.skills}
              onChange={handleChange}
            />
            <button
              className="edit-profile-form__button-save"
              type="button"
              onClick={() => handleSave("skills")}
            >
              Save
            </button>
          </>
        ) : (
          <>
            <p className="edit-profile-form__text">{formData.skills}</p>
            <button
              className="edit-profile-form__button"
              type="button"
              onClick={() => handleEdit("skills")}
            >
              Edit
            </button>
          </>
        )}
      </div>
      <div className="edit-profile-form">
        <label className="edit-profile-form__label">Experiences:</label>
        {isEditing.experiences ? (
          <>
            <textarea
              className="edit-profile-form__textarea"
              name="experiences"
              value={formData.experiences}
              onChange={handleChange}
            />
            <button
              className="edit-profile-form__button-save"
              type="button"
              onClick={() => handleSave("experiences")}
            >
              Save
            </button>
          </>
        ) : (
          <>
            <p className="edit-profile-form__text">{formData.experiences}</p>
            <button
              className="edit-profile-form__button"
              type="button"
              onClick={() => handleEdit("experiences")}
            >
              Edit
            </button>
          </>
        )}
      </div>
      <div className="edit-profile-form">
        <label className="edit-profile-form__label">Education:</label>
        {isEditing.education ? (
          <>
            <textarea
              className="edit-profile-form__textarea"
              name="education"
              value={formData.education}
              onChange={handleChange}
            />
            <button
              className="edit-profile-form__button-save"
              type="button"
              onClick={() => handleSave("education")}
            >
              Save
            </button>
          </>
        ) : (
          <>
            <p className="edit-profile-form__text">{formData.education}</p>
            <button
              className="edit-profile-form__button"
              type="button"
              onClick={() => handleEdit("education")}
            >
              Edit
            </button>
          </>
        )}
      </div>
      <div className="edit-profile-form">
        <label className="edit-profile-form__label">Projects:</label>
        {isEditing.projects ? (
          <>
            <textarea
              className="edit-profile-form__textarea"
              name="projects"
              value={formData.projects}
              onChange={handleChange}
            />
            <button
              className="edit-profile-form__button-save"
              type="button"
              onClick={() => handleSave("projects")}
            >
              Save
            </button>
          </>
        ) : (
          <>
            <p className="edit-profile-form__text">{formData.projects}</p>
            <button
              className="edit-profile-form__button"
              type="button"
              onClick={() => handleEdit("projects")}
            >
              Edit
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default ProfileFormEdit;
