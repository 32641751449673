// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-page {
  margin: 10px 20px;
  display: flex;
  height: 100vh;
}
@media screen and (min-width: 768px) {
  .dashboard-page {
    margin: 10px 40px;
  }
}
@media screen and (min-width: 1280px) {
  .dashboard-page {
    margin: 10px auto;
  }
}

.dashboard-page__content {
  display: flex;
  width: 100%;
}

.sidebar {
  background-color: #f5f5f5;
  overflow-y: auto;
}

.dashboard {
  flex: 1 1;
  padding: 20px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/DashboardPage.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAIA;EACE,iBAAA;EACA,aAAA;EACA,aAAA;AAHF;ACeI;EDfJ;IAMI,iBAAA;EAFF;AACF;ACgBI;EDrBJ;IAWI,iBAAA;EAFF;AACF;;AAKA;EACE,aAAA;EACA,WAAA;AAFF;;AAKA;EACE,yBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,SAAA;EACA,aAAA;EACA,gBAAA;AAFF","sourcesContent":["//applyify-frontend\\src\\pages\\DashboardPage.scss\n@import '../styles/variables';\n@import '../styles/mixins';\n\n.dashboard-page {\n  margin: 10px 20px;\n  display: flex;\n  height: 100vh;\n\n  @include tablet {\n    margin: 10px 40px;\n  }\n\n  @include desktop {\n    // max-width: 1100px;\n    margin: 10px auto;\n  }\n}\n\n.dashboard-page__content {\n  display: flex;\n  width: 100%;\n}\n\n.sidebar {\n  background-color: #f5f5f5;\n  overflow-y: auto;\n}\n\n.dashboard {\n  flex: 1;\n  padding: 20px;\n  overflow-y: auto;\n}","//applyify-frontend\\src\\styles\\_mixins.scss\n@mixin flex-center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  @mixin flex-column {\n    display: flex;\n    flex-direction: column;\n  }\n\n@mixin mobile {\n    @media screen and (max-width: 767px) {\n        @content;\n    }\n}\n\n@mixin tablet {\n    @media screen and (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin desktop {\n    @media screen and (min-width: 1280px) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
