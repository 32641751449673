//applyify-frontend\src\pages\DashboardPage.jsx file:
import React, { useState } from "react";
import Dashboard from "../components/Dashboard/Dashboard";
import LoadingMessages from "../components/LoadingMessages/LoadingMessages";
import Sidebar from "../components/Sidebar/Sidebar";
import "./DashboardPage.scss";

const DashboardPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);

  const handleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleJobClick = (jobId) => {
    const jobElement = document.getElementById(`job-${jobId}`);
    if (jobElement) {
      jobElement.scrollIntoView();
    }
  };

  return (
    <div className="dashboard-page">
      {isLoading ? (
        <LoadingMessages isLoading={isLoading} />
      ) : (
        <div className="dashboard-page__content">
          <Sidebar jobs={jobs} onJobClick={handleJobClick} />
          <div className="dashboard">
            <Dashboard handleLoading={handleLoading} setJobsData={setJobs} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
