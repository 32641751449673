// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile__title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #012e5b;
}`, "",{"version":3,"sources":["webpack://./src/pages/Profile.scss","webpack://./src/styles/_mixins.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAKI;ECHA,aAAA;EACA,uBAAA;EACA,mBAAA;EDGE,eAAA;EACA,cEPU;AFKhB","sourcesContent":["//applyify-frontend\\src\\pages\\Profile.scss\n@import '../styles/variables';\n@import '../styles/mixins';\n\n.profile {\n    &__title {\n      @include flex-center;\n      font-size: 2rem;\n      color: $primary-color;\n    }\n  }\n  ","//applyify-frontend\\src\\styles\\_mixins.scss\n@mixin flex-center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  @mixin flex-column {\n    display: flex;\n    flex-direction: column;\n  }\n\n@mixin mobile {\n    @media screen and (max-width: 767px) {\n        @content;\n    }\n}\n\n@mixin tablet {\n    @media screen and (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin desktop {\n    @media screen and (min-width: 1280px) {\n        @content;\n    }\n}\n","//applyify-frontend\\src\\styles\\_variables.scss\n$primary-color: #012e5b;\n$secondary-color: #ff851b;\n$background-white:#FFFFFF;\n$background-color: #97D5EE;\n$graphite: #232940;\n$green: #DB3E25;\n$cloud: #c7ccd6;\n$light-grey: #eeeeee;\n$white: #FFFFFF;\n$icon-color:#DB3E25;\n$cta-color: #014c96;\n$red: #fa2727;\n$signup-color: #f07000;\n$edit-color: #014c96;\n$light-red: #ffc7c7;\n$cover-letter-button-color:#c95e00;\n$background-orange:#ffa946;\n\n$primary-dark-blue: #2C3E50;\n$primary-white: #FFFFFF;\n\n$secondary-light-blue: #3498DB;\n$secondary-grey: #585b5e;\n\n$accent-green: #27AE60;\n$accent-orange: #E67E22;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
