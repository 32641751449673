//applyify-frontend\src\components\Header\UnauthHeader.jsx file:
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/applyify-high-resolution-logo-transparent-bl.png";
import "./Header.scss";

const UnauthHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar__menu">
        <div className="navbar__logo">
          <NavLink to="/">
            <img className="navbar__logo-img" src={Logo} alt="Applyify logo" />
          </NavLink>
        </div>
        <div className={`navbar__items ${isOpen ? "navbar__items--open" : ""}`}>
          <NavLink to="/" className="navbar__link-unauth">
            Home
          </NavLink>
          <NavLink to="/login" className="navbar__link-unauth">
            Login
          </NavLink>
          <NavLink to="/signup" className="navbar__link-signup">
            Signup
          </NavLink>
        </div>
        <div className="navbar__toggle" onClick={toggleMenu}>
          <div className="navbar__toggle-bar"></div>
          <div className="navbar__toggle-bar"></div>
          <div className="navbar__toggle-bar"></div>
        </div>
      </div>
    </nav>
  );
};

export default UnauthHeader;
