//applyify-frontend\src\pages\ForgotPasswordPage.jsx file:
import React, { useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import "./ForgotPasswordPage.scss";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/auth/forgot-password", { email });
      setMessage("Password reset email sent. Please check your email.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setMessage("Error sending password reset email. Please try again.");
    }
  };

  return (
    <div className="forgot-password">
      <h1>Forgot Password</h1>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Send Reset Email</button>
      </form>
    </div>
  );
};

export default ForgotPasswordPage;
