//applyify-frontend\src\components\Dashboard\Dashboard.jsx file:
import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { getToken } from "../../utils/auth";
import JobPostings from "./JobPostings";
import TemplateSelection from "../cvTemplateSelection/cvTemplateSelection";
import "./Dashboard.scss";

const Dashboard = ({ handleLoading, setJobsData }) => {
  const [profiles, setProfiles] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [loadingJobId, setLoadingJobId] = useState(null);
  const [successJobId, setSuccessJobId] = useState(null);
  const [errorJobId, setErrorJobId] = useState(null);
  const [editingDocumentId, setEditingDocumentId] = useState(null);
  const [editedCoverLetter, setEditedCoverLetter] = useState("");

  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get("/dashboard", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const { profiles, jobs, documents, templates } = response.data;
      setProfiles(profiles || []);
      setJobs((jobs || []).sort((a, b) => b.id - a.id));
      setDocuments(documents || []);
      setTemplates(templates || []);
      setJobsData(jobs);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const handleGenerateDocument = async (profile, job) => {
    if (!selectedTemplate) {
      alert("Please select a template before generating the document.");
      return;
    }
    handleLoading(true);
    setLoadingJobId(job.id);
    setSuccessJobId(null);
    setErrorJobId(null);
    try {
      const response = await axiosInstance.post(
        "/documents/generate",
        {
          profile,
          job: { ...job, jobId: job.id },
          templateId: selectedTemplate.id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${job.jobTitle}-CoverLetter.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      await fetchDashboardData();
      setSuccessJobId(job.id);
    } catch (error) {
      console.error(`Error generating document: ${job.id}`, error);

      // console.error("Error generating document:", error);
      setErrorJobId(job.id);
    } finally {
      setLoadingJobId(null);
      handleLoading(false);
    }
  };

  const handleDownloadPDF = async (documentId) => {
    console.log("Downloading PDF for documentId:", documentId);
    try {
      const response = await axiosInstance.get(
        `/documents/download/${documentId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `CoverLetter-${documentId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await axiosInstance.delete(`/jobposting/${jobId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      setJobs(jobs.filter((job) => job.id !== jobId));
      setDocuments(documents.filter((doc) => doc.jobId !== jobId));
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };

  const handleEditCoverLetter = (document) => {
    setEditingDocumentId(document.id);
    setEditedCoverLetter(document.coverLetter);
  };

  const handleSaveCoverLetter = async (documentId) => {
    try {
      await axiosInstance.put(
        `/documents/${documentId}`,
        { coverLetter: editedCoverLetter },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );

      await fetchDashboardData();
      setEditingDocumentId(null);
      setEditedCoverLetter("");
    } catch (error) {
      console.error("Error saving cover letter:", error);
    }
  };

  return (
    <div className="dashboard">
      <h1>User Dashboard</h1>
      <TemplateSelection
        templates={templates}
        onSelectTemplate={setSelectedTemplate}
      />
      <JobPostings
        jobs={jobs}
        documents={documents}
        handleGenerateDocument={handleGenerateDocument}
        handleDeleteJob={handleDeleteJob}
        handleEditCoverLetter={handleEditCoverLetter}
        handleSaveCoverLetter={handleSaveCoverLetter}
        loadingJobId={loadingJobId}
        successJobId={successJobId}
        errorJobId={errorJobId}
        editingDocumentId={editingDocumentId}
        editedCoverLetter={editedCoverLetter}
        setEditedCoverLetter={setEditedCoverLetter}
        profiles={profiles}
        handleDownloadPDF={handleDownloadPDF}
      />
    </div>
  );
};

export default Dashboard;
