//applyify-frontend\src\pages\Job.jsx file:
import React, { useState } from "react";
import JobForm from "../components/Job/JobForm";
import "./Job.scss";

const Job = () => {
  const [jobData, setJobData] = useState(null);

  return (
    <div className="job">
      <h1 className="job__title">Add your Job Posting Details:</h1>
      <JobForm setJobData={setJobData} />
    </div>
  );
};

export default Job;
