//applyify-frontend\src\pages\Home.jsx
import React from "react";
import { Link } from "react-router-dom";
import CreateProfileGraphic from "../assets/images/applyify-create-profile-graphic.png";
import AddJobGraphic from "../assets/images/applyify-add-job-posting-graphic.png";
import WriteCoverLetterGraphic from "../assets/images/applyify-dashboard-write-my-cover-letter-graphic.png";
import SendCoverLetterGraphic from "../assets/images/applyify-send-cover-letters-graphic.png";
import "./Home.scss";

const Home = () => {
  return (
    <div className="home">
      <section className="home__banner">
        <h1 className="home__banner-headline">Custom Cover Letters at Scale</h1>
        <p className="home__banner-slogan">
          Put your Job Hunt on Cruise Control with Applyify
        </p>
        <Link to="/signup" className="home__banner-button">
          Start My Free Trial
        </Link>
      </section>

      <section className="home__about">
        <h2 className="home__about-title">How it works</h2>
        <p className="home__about-slogan">
          By matching your most relevant skills and experiences for any given
          job posting, Applyify helps you stand out in the eyes of hiring
          managers.
        </p>
      </section>

      <section className="home__how-it-works">
        <div className="home__how-it-works-container">
          <div className="home__how-it-works-text">
            <h2 className="home__how-it-works-text-title">
              Create Your Library of Job Skill Ammunition
            </h2>
            <p className="home__how-it-works-text-description">
              Fill in as much or as little information as you want in our four
              sections: work experience, job skills, education, and projects.
              There's no need for organization here. The more information you
              add, the more tailored possibilities you’ll have.
            </p>
            <p className="home__how-it-works-text-description">
              Forget to add something? No worries, you can add or edit your
              profile anytime.
            </p>
          </div>
          <div className="home__how-it-works-image">
            <img src={CreateProfileGraphic} alt="applyify-create-profile" />
          </div>
        </div>

        <div className="home__how-it-works-container home__how-it-works-container--reverse">
          <div className="home__how-it-works-image">
            <img src={AddJobGraphic} alt="" />
          </div>
          <div className="home__how-it-works-text">
            <h2 className="home__how-it-works-text-title--reverse">
              Find a Job Posting You Like? Copy and Paste!
            </h2>
            <p className="home__how-it-works-text-description">
              Now that you made a kickass profile, it`s smooth sailing from
              here.
            </p>
            <p className="home__how-it-works-text-description">
              Simply copy & paste any job posting`s requirements, along with a
              brief blurb about the company. Then, sit back and watch the magic
              happen.
            </p>
          </div>
        </div>

        <div className="home__how-it-works-container">
          <div className="home__how-it-works-text">
            <h2 className="home__how-it-works-text-title">
              It`s Time to Click "Write My Cover Letter"
            </h2>
            <p className="home__how-it-works-text-description">
              Now that you added your first job posting, take a moment to review
              it (or don`t) and click "Write My Cover Letter" to tell Applyify
              to get to work.
            </p>
            <p className="home__how-it-works-text-description">
              After a few seconds, voilà! A detailed cover letter that stands
              out from the crowd.
            </p>
          </div>
          <div className="home__how-it-works-image">
            <img src={WriteCoverLetterGraphic} alt="" />
          </div>
        </div>

        <div className="home__how-it-works-container home__how-it-works-container--reverse">
          <div className="home__how-it-works-image">
            <img src={SendCoverLetterGraphic} alt="" />
          </div>
          <div className="home__how-it-works-text">
            <h2 className="home__how-it-works-text-title--reverse">
              Start Sending 10… no, 20… Heck, Send 50 Cover Letters a Day!
            </h2>
            <p className="home__how-it-works-text-description">
              Now that your profile is complete, Applyify has everything it
              needs to write you a tailored cover letter at a moment`s notice.
            </p>
            <p className="home__how-it-works-text-description">
              Add as many job postings as you wish, and watch as your dashboard
              fills with CV`s and your inbox fills with mouth-watering
              recruiters.
            </p>
          </div>
        </div>
      </section>

      <section className="home__cta">
        <h2 className="home__cta-title">
          Ready to Put the Job Hunt on Autopilot?
        </h2>
        <p className="home__cta-text">
          Sign up today and get your{" "}
          <span className="cta-highlight">first 3 cover letters free!</span>
        </p>
        <Link to="/signup" className="home__cta-button">
          Get Started Now
        </Link>
      </section>
    </div>
  );
};

export default Home;
