// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 30%;
  background-color: #f5f5f5;
  padding: 10px;
}
@media screen and (min-width: 768px) {
  .sidebar {
    width: 200px;
  }
}

.sidebar__list {
  list-style-type: none;
  padding: 0;
}

.sidebar__item {
  padding: 5px 0;
  font-size: 14px;
  border-bottom: #8d8d8d 1px solid;
}

.sidebar__link {
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.sidebar__link:hover {
  color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAIA;EACI,UAAA;EACA,yBAAA;EACA,aAAA;AAHJ;ACeI;EDfJ;IAMM,YAAA;EAFJ;AACF;;AAKE;EACE,qBAAA;EACA,UAAA;AAFJ;;AAKE;EACE,cAAA;EACA,eAAA;EACA,gCAAA;AAFJ;;AAKE;EACE,qBAAA;EACA,WAAA;EACA,eAAA;AAFJ;;AAKE;EACE,cAAA;AAFJ","sourcesContent":["// applyify-frontend/src/components/Sidebar/Sidebar.scss\n@import '../../styles/variables';\n@import '../../styles/mixins';\n\n.sidebar {\n    width: 30%;\n    background-color: #f5f5f5;\n    padding: 10px;\n\n    @include tablet {\n      width: 200px;\n    }\n  }\n  \n  .sidebar__list {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .sidebar__item {\n    padding: 5px 0;\n    font-size: 14px;\n    border-bottom: #8d8d8d 1px solid;\n  }\n  \n  .sidebar__link {\n    text-decoration: none;\n    color: #333;\n    font-size: 14px;\n  }\n  \n  .sidebar__link:hover {\n    color: #007bff;\n  }","//applyify-frontend\\src\\styles\\_mixins.scss\n@mixin flex-center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  @mixin flex-column {\n    display: flex;\n    flex-direction: column;\n  }\n\n@mixin mobile {\n    @media screen and (max-width: 767px) {\n        @content;\n    }\n}\n\n@mixin tablet {\n    @media screen and (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin desktop {\n    @media screen and (min-width: 1280px) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
