//applyify-frontend\src\components\Logout\Logout.jsx file:
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../../utils/auth";

const Logout = ({ onLogout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    removeToken();
    onLogout();
    navigate("/login");
  }, [navigate, onLogout]);

  return (
    <div>
      <h1>Logging Out...</h1>
    </div>
  );
};

export default Logout;
