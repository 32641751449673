//applyify-frontend\src\pages\Profile.jsx file:
import React, { useState, useEffect } from "react";
import ProfileFormCreate from "../components/ProfileCreate/CreateProfile";
import ProfileFormEdit from "../components/ProfileEdit/EditProfile";
import DeleteAccount from "../components/DeleteAccount/DeleteAccount";
import axiosInstance from "../utils/axiosConfig";
import { getToken } from "../utils/auth";
import "./Profile.scss";

const Profile = ({ onLogout }) => {
  const [profileData, setProfileData] = useState(null);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axiosInstance.get("/profile", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (response.data.profiles && response.data.profiles.length > 0) {
          setProfileData(response.data.profiles[0]);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <div className="profile">
      {profileData ? (
        <div>
          <h1>Edit Your Work Skills & Experiences</h1>
          <ProfileFormEdit
            profile={profileData}
            setProfileData={setProfileData}
          />
        </div>
      ) : (
        <div>
          <h1>Add Your Work Skills & Experiences</h1>
          <ProfileFormCreate setProfileData={setProfileData} />
        </div>
      )}
      <DeleteAccount onLogout={onLogout} />
    </div>
  );
};

export default Profile;
