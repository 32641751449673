//applyify-frontend\src\components\cvTemplateSelection\cvTemplateSelection.jsx file:
import React, { useState } from "react";
import "./cvTemplateSelection.scss";

const TemplateSelection = ({ templates, onSelectTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    onSelectTemplate(template);
  };

  return (
    <div className="template-selection">
      <h2>Select a Template</h2>
      <div className="template-list">
        {templates.map((template) => (
          <div
            key={template.id}
            className={`template-item ${
              selectedTemplate?.id === template.id ? "selected" : ""
            }`}
            onClick={() => handleTemplateSelect(template)}
          >
            <h3>{template.name}</h3>
            <p>{template.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplateSelection;
