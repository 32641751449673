//applyify-frontend\src\pages\LoginPage.jsx file:
import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./LoginPage.scss";

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      localStorage.setItem("token", token);
      onLogin();
      navigate("/profile");
    }
  }, [location, onLogin, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post("/auth/login", { email, password });
      localStorage.setItem("token", res.data.token);
      onLogin();
      navigate("/profile");
    } catch (error) {
      console.error("Error logging in:", error);
      if (error.response && error.response.status === 401) {
        setError("Incorrect password. Please try again.");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  const handleOAuthLogin = (provider) => {
    const backendUrl =
      process.env.REACT_APP_BACKEND_URL || "http://localhost:5000/api";
    window.location.href = `${backendUrl}/auth/${provider}`;
  };

  return (
    <div className="login">
      <h1 className="login__title">Login</h1>
      {error && <p className="login__error">{error}</p>}
      <form className="login__form" onSubmit={handleSubmit}>
        <div className="login__form-fields">
          <label className="login__label">Email</label>
          <input
            className="login__input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="login__form-fields">
          <label className="login__label">Password</label>
          <input
            className="login__input"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className="login__button" type="submit">
          Login
        </button>
      </form>
      <div className="oauth-buttons">
        <button
          className="button-google"
          onClick={() => handleOAuthLogin("google")}
        >
          <span className="button-google-icon"></span>
          <span className="button-google-text">Google</span>
        </button>
      </div>
      <div className="login__forgot">
        <Link to="/forgot-password" className="login__link">
          Forgot Password?
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
