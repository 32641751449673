//applyify-frontend\src\pages\TermsOfService.jsx file:
import React from "react";
import "./TermsOfService.scss";

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1 className="terms-of-service__title">Terms of Service</h1>
      <p>Last updated: July 24th, 2024</p>

      <section className="terms-of-service__section">
        <h2 className="terms-of-service__subtitle">1. Introduction</h2>
        <p>
          Welcome to Applyify. By using our web application, you agree to these
          terms of service ("Terms"). Please read them carefully. If you do not
          agree to these Terms, you may not use our services.
        </p>
      </section>

      <section className="terms-of-service__section">
        <h2 className="terms-of-service__subtitle">2. User Accounts</h2>
        <p>
          To use our services, you must create an account. You agree to provide
          accurate, current, and complete information during the registration
          process. You are responsible for maintaining the confidentiality of
          your account and password, and you are fully responsible for all
          activities that occur under your account.
        </p>
      </section>

      <section className="terms-of-service__section">
        <h2 className="terms-of-service__subtitle">3. User Data</h2>
        <p>
          We collect personal information, including but not limited to your
          name, email address, profile information (skills, experiences,
          education, and projects), job posting details, and any documents you
          generate through our services. We use this information to provide,
          improve, and personalize our services.
        </p>
      </section>

      <section className="terms-of-service__section">
        <h2 className="terms-of-service__subtitle">4. Use of Services</h2>
        <p>
          You agree to use our services only for lawful purposes and in
          accordance with these Terms. You agree not to use our services:
        </p>
        <ul className="terms-of-service__list">
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation.
          </li>
          <li>
            To impersonate or attempt to impersonate Applyify, an Applyify
            employee, another user, or any other person or entity.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the services, or which, as determined by us, may
            harm Applyify or users of the services.
          </li>
        </ul>
      </section>

      <section className="terms-of-service__section">
        <h2 className="terms-of-service__subtitle">5. Termination</h2>
        <p>
          We may terminate or suspend your account and access to our services
          immediately, without prior notice or liability, for any reason
          whatsoever, including but not limited to a breach of these Terms.
        </p>
      </section>

      <section className="terms-of-service__section">
        <h2 className="terms-of-service__subtitle">
          6. Limitation of Liability
        </h2>
        <p>
          In no event shall Applyify, nor its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential, or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from:
        </p>
        <ul className="terms-of-service__list">
          <li>Your use or inability to use the service.</li>
          <li>
            Any unauthorized access to or use of our servers and/or any personal
            information stored therein.
          </li>
          <li>
            Any interruption or cessation of transmission to or from our
            services.
          </li>
          <li>
            Any bugs, viruses, trojan horses, or the like that may be
            transmitted to or through our services by any third party.
          </li>
          <li>
            Any errors or omissions in any content or for any loss or damage
            incurred as a result of your use of any content posted, emailed,
            transmitted, or otherwise made available through the services.
          </li>
        </ul>
      </section>

      <section className="terms-of-service__section">
        <h2 className="terms-of-service__subtitle">7. Changes to Terms</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least 30 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
      </section>

      <section className="terms-of-service__section">
        <h2 className="terms-of-service__subtitle">8. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at
          support@applyify.io.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
