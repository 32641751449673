// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}
@media screen and (min-width: 768px) {
  .privacy-policy {
    margin: 0 60px;
  }
}
@media screen and (min-width: 1280px) {
  .privacy-policy {
    max-width: 1000px;
    margin: 0 auto;
  }
}
.privacy-policy__title {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}
.privacy-policy__section {
  margin-bottom: 20px;
}
.privacy-policy__subtitle {
  font-size: 25px;
  margin-bottom: 10px;
}
.privacy-policy__list {
  list-style-type: disc;
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/zFooter/PrivacyPolicy.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AAHJ;ACeI;EDfJ;IAMI,cAAA;EAFF;AACF;ACgBI;EDrBJ;IAUM,iBAAA;IACA,cAAA;EADJ;AACF;AAGE;EACE,cAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;AAKE;EACE,eAAA;EACA,mBAAA;AAHJ;AAME;EACE,qBAAA;EACA,iBAAA;AAJJ","sourcesContent":["// applyify-frontend\\src\\pages\\PrivacyPolicy.scss\n@import '../../styles/variables';\n@import '../../styles/mixins';\n\n.privacy-policy {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n\n    @include tablet {\n    margin: 0 60px;\n    }\n\n    @include desktop {\n      max-width: 1000px;\n      margin: 0 auto;\n      }\n  \n  &__title {\n    font-size: 2em;\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  &__section {\n    margin-bottom: 20px;\n  }\n  \n  &__subtitle {\n    font-size: 25px;\n    margin-bottom: 10px;\n  }\n  \n  &__list {\n    list-style-type: disc;\n    margin-left: 20px;\n  }\n}","//applyify-frontend\\src\\styles\\_mixins.scss\n@mixin flex-center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  @mixin flex-column {\n    display: flex;\n    flex-direction: column;\n  }\n\n@mixin mobile {\n    @media screen and (max-width: 767px) {\n        @content;\n    }\n}\n\n@mixin tablet {\n    @media screen and (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin desktop {\n    @media screen and (min-width: 1280px) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
