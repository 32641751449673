//applyify-frontend\src\components\ProfileCreate\CreateProfile.jsx file:
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosConfig";
import { getToken } from "../../utils/auth";
import "./CreateProfile.scss";

const ProfileFormCreate = ({ setProfileData }) => {
  const [formData, setFormData] = useState({
    skills: "",
    experiences: "",
    education: "",
    projects: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/profile", formData, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      setProfileData(response.data.profile);
      navigate("/job");
    } catch (error) {
      console.error("Error creating profile:", error);
    }
  };

  return (
    <form className="create-profile" onSubmit={handleSubmit}>
      <div className="create-profile-form">
        <label>Skills:</label>
        <textarea
          className="create-profile-form__textarea"
          name="skills"
          value={formData.skills}
          onChange={handleChange}
          placeholder="excel, powerpoint, google ads, javascript, ux design..."
          required
        />
      </div>
      <div className="create-profile-form">
        <label>Experiences:</label>
        <textarea
          className="create-profile-form__textarea"
          name="experiences"
          value={formData.experiences}
          onChange={handleChange}
          placeholder="- software engineer at XYZ Corp from 2020 to 2024, developed and maintained...
          - intern at ABC Inc from 2019 to 2020, assisted in..."
          required
        />
      </div>
      <div className="create-profile-form">
        <label>Education:</label>
        <textarea
          className="create-profile-form__textarea"
          name="education"
          value={formData.education}
          onChange={handleChange}
          placeholder="- bachelor of business from ABC university, 2018 graduate. courses included..."
          required
        />
      </div>
      <div className="create-profile-form">
        <label>Projects:</label>
        <textarea
          className="create-profile-form__textarea"
          name="projects"
          value={formData.projects}
          onChange={handleChange}
          placeholder="- developed an email marketing strategy for..."
          required
        />
      </div>
      <button className="create-profile-form__button" type="submit">
        Submit
      </button>
    </form>
  );
};

export default ProfileFormCreate;
