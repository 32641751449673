// applyify-frontend\src\components\DeleteAccount\DeleteAccount.jsx file:
import React, { useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { getToken, removeToken } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import "./DeleteAccount.scss";

const DeleteAccount = ({ onLogout }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await axiosInstance.delete("/auth/delete-account", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      removeToken();
      onLogout();
      navigate("/signup");
    } catch (error) {
      console.error("Error deleting account:", error);
      setError("Error deleting account. Please try again.");
      setIsDeleting(false);
    }
  };

  return (
    <div className="delete-account">
      <h2>Delete Account</h2>
      {error && <p className="delete-account__error">{error}</p>}
      <button
        className="delete-account__button"
        onClick={handleDelete}
        disabled={isDeleting}
      >
        {isDeleting ? "Deleting..." : "Delete My Account"}
      </button>
    </div>
  );
};

export default DeleteAccount;
